import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import ProductPageTemplate from "../../components/productPageTemplate"

const JennyPage = () => {
  const query = useStaticQuery(graphql`
    query jennyQuery {
      mainImage: file(relativePath: { eq: "IMG_0340.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      secondImage: file(relativePath: { eq: "IMG_0341.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      thirdImage: file(relativePath: { eq: "IMG_0342.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      fourthImage: file(relativePath: { eq: "Jenny_overdrag.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const product = {
    name: "Jenny",
    mainImage: query.mainImage.childImageSharp.fluid,
    secondImage: query.secondImage.childImageSharp.fluid,
    thirdImage: query.thirdImage.childImageSharp.fluid,
    fourthImage: query.fourthImage.childImageSharp.fluid,
    // secondImageNarrow: true,
    // thirdImageNarrow: true,
    description:
      "Komplett station för manuell cykeltvätt. Kan köpas med kapell.",
    facts: (
      <ul>
        <li>Spolpump med övertrycksventil</li>
        <li>Spolhandtag med ventil och borste</li>
        <li>Varmluftblås (tillval)</li>
        <li>
          Partikelrening i 3 till 4 steg:
          <ul>
            <li>Sedimenteringsbehållare</li>
            <li>Grovsugfilter</li>
            <li>Finfilter med val av 1-2 filtreringsnivåer</li>
          </ul>
        </li>
        <li>Stänkskydd</li>
      </ul>
    ),
    specs: (
      <ul>
        <li>
          Mått och vikt.
          <ul>
            <li>Största mått LxBxH 2200x800x1100mm</li>
            <li>Bredd ink stänkskydd 1080mm</li>
            <li>Spolområde LxB 1900x800mm</li>
            <li>Teknikskåp LxBxH 800x300x800mm</li>
            <li>Vätskevolym 350liter</li>
            <li>Vikt utan vätska ca 120kg</li>
          </ul>
        </li>
        <li>
          Elektrisk spec:
          <ul>
            <li>Drivspänning 1fas 230Volt</li>
            <li>Max strömförbrukning 2200W</li>
            <li>Max strömförbrukning pump 550W</li>
            <li>Max strömförbrukning varmluftblås 1600W</li>
          </ul>
        </li>
        <li>
          <ul>
            Tekniska prestanda
            <li>Vätskeflöde vid handtag (pou) 35liter/min</li>
            <li>Luftflöde 2000 liter/min</li>
          </ul>
        </li>
      </ul>
    ),
  }

  return <ProductPageTemplate product={product} />
}
export default JennyPage
